import React from 'react';
import './Modal.css'
import { AiOutlineClose } from "react-icons/ai";

const Modal = ({ isOpen, onClose, children}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={`modal `}>
      <div className="modal__content">
        <div className="modal__close" onClick={onClose}><AiOutlineClose /></div>
        {children}
      </div>
    </div>
  );
};

export default Modal;