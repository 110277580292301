import { Link } from 'react-router-dom';
import Head from '../../components/head/Head';
import './Login.css'
import { useState } from 'react';


function Login() {
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')

    return (
        <div>
            <Head />
            <div className='login'>
                <div className="login_body" >
                    <form className="login_form">
                    <h2>Welcome back</h2>
                    <p className="login_text">New to Get Property Solutions? <Link to='/signup' className="login_link">sign up</Link></p>
                        <input className='login_input' type='email' name='email' placeholder="Email address" required autoComplete onChange={(e) => {setEmail(e.target.value)}}/>
                        <input className='login_input'type='password' name='password' placeholder="Password"  required onChange={(e) => {setPassword(e.target.value)}}/>
                        <button className="login_button" type='submit'>Login</button>
                        <p className="login_text">Forgot password?</p>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login;