import './Footer.css'
import gpslogomain from '../../assets/gpslogomain.png'
import { FaFacebookF } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { FaLinkedinIn } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { MdKeyboardArrowDown } from "react-icons/md";
import React, { useState } from 'react'

const Footer = () => {
  const [clicked, setClicked] = useState(false)

  return (
    <div className='footer' >
      <section className='footer__section__one'>
        <div className='footer__section__one__head'>
          <img src={gpslogomain} alt='' className='header__logo' />
          <span>
          <FaFacebookF className='footer__section__one__head__socials'/>
          <GrInstagram className='footer__section__one__head__socials'/>
          <FaLinkedinIn className='footer__section__one__head__socials'/>
          <BsTwitterX className='footer__section__one__head__socials'/>
          </span>
        </div>
        <div className='footer__section__one__main'>
          <article className='footer__section__one__main__destinations '>
            <div className='footer__section__one__main__destinations__header' onClick={() => {setClicked(!clicked)}}>
                <p>Popular destinations</p>
                <MdKeyboardArrowDown className={clicked? 'ls__main__section__three__faq__icon' :'ls__main__section__three__faq__icon clicked'}/>
            </div>
            <div>
            <div className={clicked? 'footer__section__one__main__destinations__body' :'footer__section__one__main__destinations__body clicked'}>
              <div>
                  <p>Abuja</p>
                  <p>Lagos</p>
                  <p>Port Harcourt</p>
                  <p>Umuahia</p>
                  <p>Ibadan</p>
                  <p>Ogbomosho</p>
                  <p>Sapele</p>
                </div>
                <div>
                  <p>Abuja</p>
                  <p>Lagos</p>
                  <p>Port Harcourt</p>
                  <p>Umuahia</p>
                  <p>Ibadan</p>
                  <p>Ogbomosho</p>
                  <p>Sapele</p>
                </div>
                <div>
                  <p>Abuja</p>
                  <p>Lagos</p>
                  <p>Port Harcourt</p>
                  <p>Umuahia</p>
                  <p>Ibadan</p>
                  <p>Ogbomosho</p>
                  <p>Sapele</p>
                </div>
                <div>
                  <p>Abuja</p>
                  <p>Lagos</p>
                  <p>Port Harcourt</p>
                  <p>Umuahia</p>
                  <p>Ibadan</p>
                  <p>Ogbomosho</p>
                  <p>Sapele</p>
                </div>
                </div>
              </div>
            </article>
            <article className='footer__section__one__main__foot'>
              <div>
                <h1>Product</h1>
                <p>Become a host</p>
                <p>List properties</p>
                <p>Cancellation properties</p>
                <p>Sell properties</p>
              </div>
              <div>
                <h1>Company</h1>
                <p>About us</p>
                <p>Our story</p>
                <p>Careers</p>
                <p>Journal</p>
              </div>
              <div>
                <h1>Contact</h1>
                <p>Partnership</p>
                <p>FAQ</p>
                <p>Get in touch</p>
              </div>
          </article>
        </div>
      </section>
      <section className='footer__section__two'>
        <p>&copy; Copyright Get Property Solutions 2024</p>
        <p>Terms | Privacy | Legal</p>
      </section>
    </div>
  )
}

export default Footer