import React, { useEffect, useState } from 'react'
import './Header.css'
import gpslogomain from '../../assets/gpslogomain.png'
import { FiMenu } from 'react-icons/fi'
import { RiHome6Fill } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
import { RiAccountCircleFill } from "react-icons/ri";
import { CiMenuFries } from "react-icons/ci";
import Modal from '../modal/Modal.js';
import '../../pages/signup/Signup.css'

function Header() {
    // controls the visibility of the modal component
    const [isOpen, setIsOpen] = useState({
      signup: false,
      signup2: false,
      login: false,
    });

    const openModal1 = () => {
      setIsOpen(prevState => ({
        ...prevState,
        login: false,
        signup2: false,
        signup: true
      }));
    };
    const openModal2 = () => {
      setIsOpen(prevState => ({
        ...prevState,
        signup: false,
        login: true
      }));
    };

    const openModal3 = () => {
      setIsOpen(prevState => ({
        ...prevState,
        signup: false,
        login: false,
        signup2: true
      }));
    };
  
    const closeModal = () => {
      setIsOpen(prevState => ({
        ...prevState,
        signup: false,
        login: false,
        signup2: false
      }));
    };

    //

    const [firstname,setFirstname] = useState('')
    const [lastname,setLastname] = useState('')
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [menu, setMenu] = useState(false)
    const navigate = useNavigate()

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
      const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const headerStyle = {
      backgroundColor: scrollPosition > 0 ? 'white' : 'white',
      transition: 'background-color 0.3s ease-in-out',
    };

    return(
        <div className='header'  style={headerStyle}>
            <img src={gpslogomain} alt='' className='header__logo' onClick={() => {navigate('/')}}/>
            <div>
                <ul className='header__nav'>
                    <li className='header__nav__list'>
                    <Link to='' className='header__nav__list'>Property management</Link>
                      <ul className='header__submenu'>
                        <li>Rental
                          <ul className='header__submenu2'>
                            <li>Commercial</li>
                            <li>Residential</li>
                          </ul>
                        </li>
                        <li> Buy
                          <ul className='header__submenu2'>
                            <li>plota</li>
                            <li>plotb</li>
                            <li>plotc</li>
                          </ul>
                          </li>
                        <li>Lease
                          <ul className='header__submenu2'>
                            <li>dataa</li>
                            <li>databb</li>
                            <li>datac</li>
                          </ul>
                          </li>
                      </ul>
                    </li>
                    <li>         
                    <li className='header__nav__list'>
                        <Link to='#' className='header__nav__list'>Student property</Link>
                        <ul className='header__submenu'>
                        <li>Shared appatment
                        </li>
                        <li> Hostel
                          <ul className='header__submenu2'>
                            <li>Private hostel</li>
                            <li>University hostel</li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    </li>
                    <li>
                    <Link to='' className='header__nav__list'>Property construction</Link>
                    </li>
                    <li>
                    <Link to='' className='header__nav__list'>Services</Link>
                    </li>
                    <li>
                    <Link to='' className='header__nav__list'>Agent</Link>
                    </li>
                </ul>
            </div>
            <div className='header__user'>
                <h3 onClick={() => {navigate('/list-property')}}>List property</h3>
                <div className='header__account'>
                  <button><CiMenuFries className='header__user__icon'/><RiAccountCircleFill className='header__user__icon'/>
                  </button>
                  <ul className='header__submenu'>
                    <li onClick={openModal2}> Login
                    </li>
                    <li onClick={openModal1}>Signup
                      </li>
                  </ul>
                </div>
            </div>

            <FiMenu className='home_menu' onClick={() => {setMenu(true)}}/>
            <div className={menu? 'home_sidebar_true' : 'home_sidebar'}>
            </div>

            <Modal isOpen={isOpen.login} onClose={closeModal}>
              <div className='login'>
                <div className="login_body" >
                    <form className="login_form">
                    <h2>Welcome back</h2>
                    <p className="login_text">New to Get Property Solutions? <span  onClick={openModal1} className="login_link">sign up</span></p>
                        <input className='login_input' type='email' name='email' placeholder="Email address" required autoComplete onChange={(e) => {setEmail(e.target.value)}}/>
                        <input className='login_input'type='password' name='password' placeholder="Password"  required onChange={(e) => {setPassword(e.target.value)}}/>
                        <button className="login_button" type='submit'>Login</button>
                        <p className="login_text">Forgot password?</p>
                    </form>
                </div>
            </div>
            </Modal>
            <Modal isOpen={isOpen.signup} onClose={closeModal}>
              <div className='signup'>
                <div className='signup_div'>
                    <form className='signup_form'>
                        <h1>Signin or create an account</h1>
                        <input placeholder='Enter email address' id='email' className='signup_input' type='email' required onChange={(e) => {setEmail(e.target.value)}}/>
                        <div>
                            <button type='submit' onClick={openModal3}>Continue</button>
                            <p>already have an account? <span onClick={openModal2}>Login</span></p>
                        </div>
                    </form>
                    <p>By signing in or creating an account, you agree with our Terms & Conditions and Privacy Statement</p>
                </div>
            </div>
            </Modal>
            <Modal isOpen={isOpen.signup2} onClose={closeModal}>
              <div className='signup'>
                <div className='signup_div'>
                    <form className='signup_form'>
                        <h1>Let's get started</h1>
                        <input type="text" value={email} readOnly  className='signup_input'/>
                        <input placeholder='First name' id='firstname' className='signup_input' type='text' required onChange={(e) => {setFirstname(e.target.value)}}/>
                        <input placeholder='Last name' id='lastname' className='signup_input' type='text' required onChange={(e) => {setLastname(e.target.value)}}/>
                        <input placeholder='Password' id='email' className='signup_input' type='password' required onChange={(e) => {setPassword(e.target.value)}}/>
                        <div>
                            <button type='submit' onClose={closeModal}>Sign up</button>
                        </div>
                    </form>
                </div>
              </div>
            </Modal>
        </div>
    )
}

export default Header;