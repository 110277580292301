import React, { useState } from 'react'
import './Home.css'
import Header from '../../components/header/Header'
import poppty_img1 from '../../assets/poppty_img1.png'
import poppty_img2 from '../../assets/poppty_img2.png'
import poppty_img3 from '../../assets/poppty_img3.png'
import poppty_img4 from '../../assets/poppty_img4.png'
import poppty_img5 from '../../assets/christian-koch-D_4R9CcYZOk-unsplash.jpg'
import poppty_img6 from '../../assets/collov-home-design-H-1j_s0dhCw-unsplash.jpg'
import poppty_img7 from '../../assets/daniil-silantev-sN4u56baSB0-unsplash.jpg'
import poppty_img8 from '../../assets/good-days-digital-Vra_DPrrBlE-unsplash.jpg'
import poppty_img9 from '../../assets/julian-gentilezza-ctUWE7BUEzE-unsplash.jpg'
import poppty_img10 from '../../assets/kirill-9uH-hM0VwPg-unsplash.jpg'
import poppty_img11 from '../../assets/tina-witherspoon-fpKrFJUiPA8-unsplash.jpg'
import poppty_img12 from '../../assets/trend-K9pU2u0Z5WU-unsplash.jpg'
import poppty_img13 from '../../assets/minh-pham-7pCFUybP_P8-unsplash.jpg'
import poppty_img14 from '../../assets/minh-pham-OtXADkUh3-I-unsplash.jpg'
import poppty_img15 from '../../assets/minh-pham-YAPZt8wxMO4-unsplash.jpg'
import poppty_img16 from '../../assets/patrick-perkins-3wylDrjxH-E-unsplash.jpg'
import homereviewimg1 from '../../assets/homereviewimg1.png'
import homereviewimg2 from '../../assets/homereviewimg2.png'
import homepageimage from '../../assets/homepageimage.png'
import homepageimage2 from '../../assets/homepageimage2.png'
import homepageimage3 from '../../assets/alexandra-gorn-W5dsm9n6e3g-unsplash.jpg'
import { MdApartment } from "react-icons/md";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { PiCubeTransparentThin } from "react-icons/pi";
import Footer from '../../components/footer/Footer'
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { useNavigate } from 'react-router-dom'

function Home() {
  const navigate = useNavigate()

  // React-slideshow-image library for hero background fade
  const divStyle = {
    backgroundSize: 'cover',
    minHeight: '47.5vh',
  }
  const slideImages = [
    {
      url: homepageimage2,
      caption: 'Slide 1',
      h3: 'Energy for the future',
      h1: 'Evolving energy for today and tomorrow',
      p: 'Et adipisicing aliquip ea exercitation ipsum voluptate quis anim anim elit. Laborum occaecat proident officia veniam deserunt fugiat reprehenderit magna laborum culpa sint ut reprehenderit.',
    },
    {
      url: homepageimage,
      caption: 'Slide 2',
      h3: 'Nuturing the future',
      h1: 'Sowing seed of clean energy',
      p: 'Nostrud ut velit adipisicing incididunt nulla sit sint culpa velit cillum occaecat qui ullamco eiusmod. Pariatur mollit ad est est Lorem reprehenderit sint quis occaecat id eu.',
    },
    {
      url: homepageimage3,
      caption: 'Slide 3',
      h3: 'Nigerian through and through',
      h1: 'For every family out there',
      p: 'Id reprehenderit velit adipisicing adipisicing. ',
    },
  ];  
  
  // sets the dispay for the React-slideshow-image to none
  const buttonStyle = {
    display: 'none'
};

const properties = {
    prevArrow: <button style={{ ...buttonStyle }}></button>,
    nextArrow: <button style={{ ...buttonStyle }}></button>
}


  return (
    <div className='home'>
      <Header />
      <div className='home__main'>
      <section className="slide-container ">
        <Fade {...properties}>
        {slideImages.map((slideImage, index)=> (
            <div key={index}>
            <div  className="home__main__section__one" style={{ ...divStyle, backgroundImage: `url(${slideImage.url})` }}>
              <div className='home__main__section__one__hero'>
              <h1>Enjoy the finest stay</h1>
              <h3>Stay in the most remarkabe homes tailored specifically for you</h3>
              <div className='home__main__section__one__searchbox'>
                <input placeholder='Add destinations'/>
                <input placeholder='Add dates'/>
                <input placeholder='Add guests'/>
                <button>
                  Search
                </button>
              </div>
          </div>
            </div>
            </div>
        ))} 
        </Fade>
        <div >
            
        </div>
        </section>
        <section className='home__main__section__two'>
          <h1>Popular properties</h1>
          <h3>Browse through trending properties near you</h3>
          <div className='home__main__section__two__poppty'>
            <div onClick={() => {navigate('/rooms/091123')}}>
              <img src={poppty_img13} alt='' className='home__main__section__two__poppty__img' />
              <h1>Greenland bungalow</h1>
              <h3>Lekki phase 1, Nigeria</h3>
              <h3>#12,500,000</h3>
            </div>
            <div>
              <img src={poppty_img14} alt='' className='home__main__section__two__poppty__img' />
              <h1>Greenland bungalow</h1>
              <h3>Lekki phase 1, Nigeria</h3>
              <h3>#12,500,000</h3>
            </div>
            <div>
              <img src={poppty_img5} alt='' className='home__main__section__two__poppty__img' />
              <h1>Greenland bungalow</h1>
              <h3>Lekki phase 1, Nigeria</h3>
              <h3>#12,500,000</h3>
            </div>
            <div>
              <img src={poppty_img6} alt='' className='home__main__section__two__poppty__img' />
              <h1>Greenland bungalow</h1>
              <h3>Lekki phase 1, Nigeria</h3>
              <h3>#12,500,000</h3>
            </div>
          </div>
        </section>
        <section className='home__main__section__three'>
          <h1>Why choose us?</h1>
          <div className='home__main__section__three__row'>
            <div>
              <div>
                  <MdApartment className='home__main__section__three__row__icon'/>
              </div>
              <h1>Wide range of properties</h1>
              <p>Excepteur ex enim nulla sint laborum ea cillum aute. Aliquip sit consectetur consectetur consectetur magna ea ex.</p>
            </div>
            <div>
              <div>
                <FaMoneyCheckAlt  className='home__main__section__three__row__icon'/>
              </div>
              <h1>Financing made easy</h1>
              <p>Labore commodo pariatur incididunt duis Lorem. Est id dolor deserunt laboris sint aliquip aute nostrud cillum culpa id excepteur.</p>
            </div>
            <div>
              <div>
                <PiCubeTransparentThin  className='home__main__section__three__row__icon'/>
              </div>
              <h1>Transparency</h1>
              <p>Minim qui enim incididunt proident do. Anim aute amet Lorem est velit ipsum fugiat anim laborum. Do est laboris culpa minim.</p>
            </div>
          </div>
        </section>
        <section className='home__main__section__four'>
          <div className='home__main__section__four__seg1'>
            <h1>Peace of mind guaranteed with every boooking</h1>
            <p>We promise expert-vetted homes, total transparency and exceptional service.</p>
          </div>
          <div>
            <div>
            <div  className='home__main__section__four__seg2'>
              <div>
                <img src={poppty_img9} alt='' className='home__main__section__four__img' />
                <h1>Greenland bungalow</h1>
                <h3>The lake side retreat, Ibadan.</h3>
              </div>
              <div>
                <img src={poppty_img10} alt='' className='home__main__section__four__img' />
                <h1>Greenland bungalow</h1>
                <h3>The lake side retreat, Ibadan.</h3>
              </div>
              <div>
                <img src={poppty_img11} alt='' className='home__main__section__four__img' />
                <h1>Greenland bungalow</h1>
                <h3>Edge of the mountain, Umuahia</h3>
              </div>
              <div>
                <img src={poppty_img12} alt='' className='home__main__section__four__img' />
                <h1>Greenland bungalow</h1>
                <h3>Edge of the mountain, Umuahia</h3>
              </div>
              <div>
                <img src={poppty_img4} alt='' className='home__main__section__four__img' />
                <h1>Greenland bungalow</h1>
                <h3>Clear nights, Kano.</h3>
              </div>
              <div>
                <img src={poppty_img3} alt='' className='home__main__section__four__img' />
                <h1>Greenland bungalow</h1>
                <h3>Clear nights, Kano.</h3>
              </div>
            </div>
            </div>
          </div>
        </section>
        <section className='home__main__section__seven'>
           <h1>Book your dream stay today</h1>
           <div>
            <div  className='home__main__section__seven__seg2'>
              <div>
                <img src={poppty_img5} alt='' className='home__main__section__seven__img' />
                <h1>Greenland bungalow</h1>
                <h3>The lake side retreat, Ibadan.</h3>
              </div>
              <div>
                <img src={poppty_img6} alt='' className='home__main__section__seven__img' />
                <h1>Greenland bungalow</h1>
                <h3>The lake side retreat, Ibadan.</h3>
              </div>
              <div>
                <img src={poppty_img7} alt='' className='home__main__section__seven__img' />
                <h1>Greenland bungalow</h1>
                <h3>Edge of the mountain, Umuahia</h3>
              </div>
              <div>
                <img src={poppty_img8} alt='' className='home__main__section__seven__img' />
                <h1>Greenland bungalow</h1>
                <h3>Edge of the mountain, Umuahia</h3>
              </div>
              <div>
                <img src={poppty_img4} alt='' className='home__main__section__seven__img' />
                <h1>Greenland bungalow</h1>
                <h3>Clear nights, Kano.</h3>
              </div>
              <div>
                <img src={poppty_img4} alt='' className='home__main__section__seven__img' />
                <h1>Greenland bungalow</h1>
                <h3>Clear nights, Kano.</h3>
              </div>
              <div>
                <img src={poppty_img1} alt='' className='home__main__section__seven__img' />
                <h1>Greenland bungalow</h1>
                <h3>The lake side retreat, Ibadan.</h3>
              </div>
            </div>
           </div>
          <div  className='home__main__section__seven__seg2 home__responsive__none'>
            <div>
              <img src={poppty_img16} alt='' className='home__main__section__seven__img' />
              <h1>Greenland bungalow</h1>
              <h3>The lake side retreat, Ibadan.</h3>
            </div>
            <div>
              <img src={poppty_img2} alt='' className='home__main__section__seven__img' />
              <h1>Greenland bungalow</h1>
              <h3>The lake side retreat, Ibadan.</h3>
            </div>
            <div>
              <img src={poppty_img15} alt='' className='home__main__section__seven__img' />
              <h1>Greenland bungalow</h1>
              <h3>Edge of the mountain, Umuahia</h3>
            </div>
            <div>
              <img src={poppty_img4} alt='' className='home__main__section__seven__img' />
              <h1>Greenland bungalow</h1>
              <h3>Edge of the mountain, Umuahia</h3>
            </div>
            <div>
              <img src={poppty_img4} alt='' className='home__main__section__seven__img' />
              <h1>Greenland bungalow</h1>
              <h3>Clear nights, Kano.</h3>
            </div>
            <div>
              <img src={poppty_img4} alt='' className='home__main__section__seven__img' />
              <h1>Greenland bungalow</h1>
              <h3>Clear nights, Kano.</h3>
            </div>
            <div>
              <img src={poppty_img1} alt='' className='home__main__section__seven__img' />
              <h1>Greenland bungalow</h1>
              <h3>The lake side retreat, Ibadan.</h3>
            </div>
          </div>
        </section>
        <section className='home__main__section__five'>
          <div>
            <h1>List your property on Get properties solutions and open your door to rental income</h1>
            <button>List properties</button>
          </div>
        </section>
        <section className='home__main__section__six'>
          <h1>Rated excellent for a reason</h1>
          <div className='home__main__section__six__bg'>
            <div className='home__main__section__six__fg'>
              <div className='primary_one'>
                <img src={homereviewimg1} alt='' className='home__section__six__reviewimg'/>
                <div>
                  <p>"Nostrud eu voluptate nulla deserunt mollit nostrud magna quis elit anim enim culpa. Duis mollit non adipisicing pariatur mollit amet enim pariatur."</p>
                </div>
              </div>
              <div className='primary_one'>
                <img src={homereviewimg2} alt='' className='home__section__six__reviewimg'/>
                <div>
                  <p>"Ea velit deserunt nisi dolor consequat commodo fugiat reprehenderit nulla esse reprehenderit incididunt exercitation."</p>
                </div>
              </div>
              <div className='primary_one'>
                <img src={homereviewimg1} alt='' className='home__section__six__reviewimg'/>
                <div>
                  <p>"Sit ipsum occaecat dolore non. Adipisicing elit consequat labore Lorem Lorem magna officia deserunt magna nulla."</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default Home