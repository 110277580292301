import { useNavigate } from 'react-router-dom';
import gpslogomain from '../../assets/gpslogomain.png'
import { RiAccountCircleFill } from "react-icons/ri";
import { CiMenuFries } from "react-icons/ci";
import './Head.css'

function Head() {
    const navigate = useNavigate()
    return(
        <div className='head'>
            <img src={gpslogomain} alt='' className='head__logo'  onClick={() => {navigate('/')}}/>
            <div className='head__user'>
                <p>Get started</p>
                <button onClick={() => {navigate('/signup')}}><CiMenuFries className='header__user__icon'/><RiAccountCircleFill className='header__user__icon'/></button>
            </div>
        </div>
    )
}

export default Head;