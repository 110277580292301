import Head from '../../components/head/Head';
import Header from '../../components/header/Header';
import './Becomeahostsuccess.css'

function Becomeahostsuccess(){
    return(
        <div className='becomeahostsuccess__main'>
            <Header />
            <div className='becomeahostsuccess'>
                <div>
                    <h1>Your information was sent</h1>
                    <p>We'll review your listing and get in touch if your home meets our selection criteria to be included in our collection.</p>
                </div>
            </div>

        </div>
    )
}

export default Becomeahostsuccess;