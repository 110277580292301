import { useState } from 'react';
import Footer from '../../components/footer/Footer';
import Head from '../../components/head/Head';
import './Becomeahost.css'
import listimg from '../../assets/5164959.png'
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header/Header';

function Becomeahost(){
    const [lastname,setLastname] = useState('')
    const [firstname,setFirstname] = useState('')
    const [email,setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [existingListing, setExistingListing] = useState('')
    const navigate = useNavigate()

    return(
        <div>
        <Header />
        <div className='becomeahost'>
            <div className='becomeahost__hero'>
                <h1>Apply to list your exceptional home on Get properties Solutions</h1>
                <p>I simply adore the team at Plum Guide. Top-notch professionals who are a joy to work with. The writing and photography for our home is amazing. I know for sure that the guests and properties are well vetted.</p>
                <img src={listimg} alt='' />
            </div>
            <div className='becomeahost__form'>
                <form>
                    <span>
                        <div>
                            <h3 className='listing__h3'>Last name:</h3>
                            <input className='list__input' type='text' required onChange={(e) => {setLastname(e.target.value)}}/>
                        </div>
                        <div>
                            <h3 className='listing__h3'>First name</h3>
                            <input className='list__input' type='text' required onChange={(e) => {setFirstname(e.target.value)}}/>
                        </div>
                    </span>
                    <h3  className='listing__h3' for='email'>Email</h3>
                    <input id='email' className='listing__input' type='email' required onChange={(e) => {setEmail(e.target.value)}}/>
                    <h3 className='listing__h3'>Phone number</h3>
                    <input  className='listing__input' type='number' name='phonenumber' required onChange={(e) => {setPhone(e.target.value)}}/>
                    <h3 className='listing__h3'>Link to existing listing</h3>
                    <p>Alternatively, add a link to your website or a link that displays the home photos (e.g dropbox link, etc.)</p>
                    <input className='listing__input' type='text' required onChange={(e) => {setExistingListing(e.target.value)}}/>
                    <h3 className='listing__h3'>Listing location</h3>
                    <p>Select the address from the suggested list</p>
                    <input className='listing__input' type='text' required onChange={(e) => {setExistingListing(e.target.value)}}/>
                    <button onClick={() =>{navigate('/list-property/become-a-host/success')}}>Apply now</button>
                    <p>By signing up, you agree to be contacted on the details you have provided. Your personal information will be handled in accordance with our privacy policy.</p>
                </form>

            </div>
        </div>
        <Footer />
        </div>
    )
}

export default Becomeahost;