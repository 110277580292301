import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import './Product.css'
import product__img1 from '../../assets/patrick-perkins-3wylDrjxH-E-unsplash.jpg'
import product__img2 from '../../assets/tina-witherspoon-fpKrFJUiPA8-unsplash.jpg'
import poppty_img13 from '../../assets/minh-pham-7pCFUybP_P8-unsplash.jpg'
import poppty_img14 from '../../assets/minh-pham-OtXADkUh3-I-unsplash.jpg'
import poppty_img5 from '../../assets/christian-koch-D_4R9CcYZOk-unsplash.jpg'
import poppty_img6 from '../../assets/collov-home-design-H-1j_s0dhCw-unsplash.jpg'
import { GoCheckCircleFill } from "react-icons/go";
import { GiBed } from "react-icons/gi";
import { LiaBathSolid } from "react-icons/lia";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Product() {
    const navigate = useNavigate()

    return(
        <div>
            <Helmet>
                <title>Greenland Bungalow</title>
            </Helmet>
            <Header />
            <div className='product'>
                <section className='product__section__one'>
                    <img src={product__img1} alt='' />
                    <img src={product__img2} alt='' />
                </section>
                <section className='product__section__two'>
                    <div className='product__section__two__start'>
                        <article className='product__section__two__article1'>
                            <h1>Greenland bungalow</h1>
                            <p>
                                Set less than one hundred metres from Ceglie Messapica's bustling pedestrianised hub and just an easy drive from the coast and Ostuni, the location of this apartment is spot-on. While the period home's vaulted ceilings and exposed stonework are impressive, there are a few compromises to make. Expect a small kitchenette that's better suited to lighter bites rather than large slap-up meals, and there's no outdoor space to speak of. But for a bolthole this characterful, we can forgive that.
                            </p>
                            <p>
                                Proident sit incididunt excepteur consequat ut dolore sit voluptate. Ullamco laboris ullamco duis laborum qui et reprehenderit enim anim commodo labore ipsum pariatur. Eiusmod id reprehenderit Lorem officia elit duis aute proident duis deserunt amet anim. Anim amet voluptate veniam nostrud. Esse anim nostrud dolor laborum anim enim.
                            </p>
                        </article>
                        <article className='product__section__two__article2'>
                            <h3>What to expect?</h3>
                            <h4>Highlights</h4>
                            <div className='product__section__two__article2__poppty'>
                                <div onClick={() => {navigate('/rooms/091123')}}>
                                <img src={poppty_img13} alt='' className='product__section__two__article2__poppty__img' />
                                <h3>Deserunt minim aliquip occaecat duis nisi occaecat sint sunt.</h3>
                                </div>
                                <div>
                                <img src={poppty_img14} alt='' className='product__section__two__article2__poppty__img' />
                                <h3>Id eu minim velit dolore eiusmod nisi. Id eu minim velit dolore eiusmod nisi.</h3>
                                </div>
                                <div>
                                <img src={poppty_img5} alt='' className='product__section__two__article2__poppty__img' />
                                <h3>Anim tempor consequat incididunt amet enim qui cillum.</h3>
                                </div>
                            </div>
                        </article>
                        <article className='product__section__two__article3'>
                            <h3>About this stay</h3>
                            <h4>Amenities</h4>
                            <div className='product__section__two__article3__amenities'>
                                <span><GoCheckCircleFill className='product__amenities__checkmark' /> Desk / workplace</span>
                                <span><GoCheckCircleFill className='product__amenities__checkmark' /> Air conditioning</span>
                                <span><GoCheckCircleFill className='product__amenities__checkmark' /> Washing machine</span>
                                <span><GoCheckCircleFill className='product__amenities__checkmark' /> Lift access</span>
                                <span><GoCheckCircleFill className='product__amenities__checkmark' /> Wi-fi</span>
                                <span><GoCheckCircleFill className='product__amenities__checkmark' /> Pets allowed</span>
                                <span><GoCheckCircleFill className='product__amenities__checkmark' /> Rooftop terrace</span>
                                <span><GoCheckCircleFill className='product__amenities__checkmark' /> TV</span>

                            </div>
                            <h4>Bedrooms and bathrooms</h4>
                            <div className='product__section__two__article3__bedrooms'>
                                <span ><GiBed className='product__bedrooms__icon' />2 bedrooms, 2 beds</span>
                                <span><LiaBathSolid className='product__bedrooms__icon'/>2 bathrooms</span>
                            </div>
                        </article>
                        <article className='product__section__two__article4'>
                            <h3>Extras</h3>
                            <h4>Additional fees</h4>
                            <ul>
                                <li>While pets are welcome to join your during your stay, an additional fee will apply – please enquire with your host at the time of booking</li>
                                <li>Two cleans per month are included in the price – you can add an extra clean at an additional cost if needed</li>
                                <li>There may be some extra fees to pay here, such as charges for utilities, final cleaning fees, city tax and security deposits – all of which you may need to pay in cash to the homeowner. Please reach out during the booking process to confirm.</li>
                            </ul>
                        </article>
                    </div>
                    <div className='product__section__two__end'>
                        <div>
                            <button className="product__section__two__end__button" onClick={() => {navigate('/rooms/payment')}}>Check availability</button>
                        </div>
                    </div>
                </section>
                <section className='product__section__three'>
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.6654220632436!2d3.507026175985806!3d6.437000024160492!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf67d389945e5%3A0x1866152ec04410c2!2sKm%2042%20Lekki%20-%20Epe%20Expy%2C%20Lekki%20Penninsula%20II%2C%20Lagos%20101245%2C%20Lagos!5e0!3m2!1sen!2sng!4v1706347243090!5m2!1sen!2sng" 
                        width="600" 
                        height="450" 
                        style={{border: '0', width: '100%'}} 
                        allowfullscreen="" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </section>
            </div>
            <Footer />
        </div>
    )
}

export default Product;