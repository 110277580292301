import { Helmet } from 'react-helmet'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import './Payment.css'

function Payment() {
    return(
        <div>
            <Helmet>
                <title>Review booking details - Greenland Bungalow</title>
            </Helmet>
            <Header />
            <div className='payment'>

            </div>
            <Footer />
        </div>
    )
}

export default Payment;