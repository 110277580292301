import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/home/Home';
import './App.css'
import Listproperty from './pages/listproperty/Listproperty';
import Login from './pages/login/Login';
import Becomeahost from './pages/becomeahost/Becomeahost';
import Becomeahostsuccess from './pages/becomeahostsuccess/Becomeahostsuccess';
import Product from './pages/product/Product';
import ScrollToTop from './utils/ScrollToTop';
import Test from './components/modal/Test';
import Payment from './pages/payment/Payment';

function App() {
  return (
    <div>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/list-property" element={<Listproperty />}/>
            <Route path="/list-property/become-a-host" element={<Becomeahost />}/>
            <Route path="/list-property/become-a-host/success" element={<Becomeahostsuccess />}/>
            <Route path="/rooms/091123" element={<Product />}/>
            <Route path="/rooms/payment" element={<Payment />}/>
            <Route path="/devpath" element={<Test />}/>
          </Routes>
        </BrowserRouter>
    </div>
  )
}

export default App
